import { createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import { error } from '@/utils';
import { cancelAllPendingRequest } from '@/api';
import { isEmpty, isEqual } from 'lodash-es';
import { store } from '@/store';
import OmsRoute from '@/router/modules/oms.router';
import CrmRoute from '@/router/modules/crm.router';
import CfaRouter from '@/router/modules/cfa.router';
import DrsRoute from '@/router/modules/dcm.router';
import NpmRoute from '@/router/modules/npm.router';
import PsmRoute from '@/router/modules/psm.router';
import SsmRoute from '@/router/modules/ssm.router';
import UserRoute from '@/router/modules/usm.router';
import ClrRoute from '@/router/modules/clr.router';
import AclRoute from '@/router/modules/lms.router';
import CsmRoute from '@/router/modules/csm.router';
import CfmRoute from '@/router/modules/cfm.router';
import CfsRouter from '@/router/modules/cfs.router';
import BmsRoute from '@/router/modules/bms.router';
import SimRoute from '@/router/modules/sim.router';
import StsRouter from '@/router/modules/sts.router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    component: () => import('@/views/login/LoginPage.vue'),
    name: 'login'
  },
  StsRouter,
  OmsRoute,
  CrmRoute,
  DrsRoute,
  NpmRoute,
  PsmRoute,
  SsmRoute,
  UserRoute,
  ClrRoute,
  AclRoute,
  CsmRoute,
  CfmRoute,
  CfaRouter,
  CfsRouter,
  BmsRoute,
  SimRoute,
  {
    name: 'oms.404',
    component: () => import('@/views/404/404Page.vue'),
    path: '/:defaultParams(.*)*'
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  cancelAllPendingRequest();
  const { name } = to;
  // 没登陆之前强制到登陆界面
  if (isEmpty(store.state.token) && !isEqual(name, 'login')) return { name: 'login' };
});
router.onError((e) => {
  error('路由信息错误');
});

export default router;
export { routes };
