const lostTraceRoute = {
  path: '/cfm/lostTrace',
  name: 'cfm.lostTrace',
  component: () => import('@/views/cfm/lostTrace/LostTracePage.vue')
};

const hzcnTraceQuantityRoute = {
  path: '/cfm/hzcn/trace/quantity',
  name: 'cfm.hzcn.trace.quantity',
  component: () => import('@/views/cfm/hzcnTrace/HzcnTracePage.vue'),
};

const temuTraceQuantityRoute = {
  path: '/cfm/temu/trace/quantity',
  name: 'cfm.temu.trace.quantity',
  component: () => import('@/views/cfm/temuTrace/TemuTracePage.vue'),
};

const temuClearanceAgingRoute = {
  path: '/cfm/temu/clearance/aging',
  name: 'cfm.temu.clearance.aging',
  component: () => import('@/views/cfm/temuFranceEvaluation/TemuFranceEvaluationPage.vue'),
};

const temuSpainExamineRoute = {
  path: '/cfm/temu/spain/examine',
  name: 'cfm.temu.spain.examine',
  component: () => import('@/views/cfm/temuSpainEvaluation/TemuSpainEvaluationPage.vue'),
};

export default {
  name: 'cfm',
  component: () => import('@/layout/Layout.vue'),
  path: '/',
  children: [
    lostTraceRoute,
    hzcnTraceQuantityRoute,
    temuTraceQuantityRoute,
    temuClearanceAgingRoute,
    temuSpainExamineRoute
  ]
};
