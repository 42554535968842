import http from '@/api';

const baseUrl = '/oms-admin/options';

const getClientOptions = () => {
  return http.get({}, `${baseUrl}/crm/clients`);
};

const getClearanceType = () => {
  return http.get({}, `${baseUrl}/crm/getClearanceTypes`);
};

const getTransferProducts = (params: any) => http.get(params, `${baseUrl}/psm/getTransferProduct`);

const getTransferProductMappings = () => http.get({}, `${baseUrl}/oms/getTransferProductAlias`);

const getBlackListName = () => http.get({}, `${baseUrl}/psm/getBlackListName`);

const getShipmentSenderAddresses = () => http.get({}, '/oms-shipment/admin/shipmentSenderAddress/getAddressLabelList');

const getTransferProductChannels = (params: any) => http.get(params, `${baseUrl}/psm/channel`);

const getPriceRoutes = (params: any) => http.get(params, `${baseUrl}/drs/findForSelect`);

const getPriceGradeNames = (priceType: string) =>
  http.get({}, `${baseUrl}/crm/getPriceGradeName/${priceType}`);

export default {
  getClientOptions,
  getClearanceType,
  getTransferProducts,
  getTransferProductMappings,
  getShipmentSenderAddresses,
  getTransferProductChannels,
  getPriceRoutes,
  getPriceGradeNames,
  getBlackListName
};
